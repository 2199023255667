import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = [
  {
    amount: 10,
    price: 9.7,
    description: [
      'Pay-as-you-go model: no subscriptions required',
      '1 credit = 1 photorealistic image',
      'Super easy: upload one selfie and get multiple images',
      'Bundles: choose a pack of photos and get similar ones from one selfie',
      'Selectors: choose camera, angle, lighting, background, and more',
      'Custom Instructions: specify every detail with prompts',
      'Feed: get inspired by scrolling through images',
      'Models: follow real creators and their stunning creations',
    ],
    buttonText: 'Get 10 credits',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
  },
  {
    amount: 20,
    price: 14.7,
    description: [
      'Pay-as-you-go model: no subscriptions required',
      '1 credit = 1 photorealistic image',
      'Super easy: upload one selfie and get multiple images',
      'Bundles: choose a pack of photos and get similar ones from one selfie',
      'Selectors: choose camera, angle, lighting, background, and more',
      'Custom Instructions: specify every detail with prompts',
      'Feed: get inspired by scrolling through images',
      'Models: follow real creators and their stunning creations',
      'Save more with this bundle!',
    ],
    buttonText: 'Get 20 credits',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
  },
  {
    amount: 50,
    price: 29.7,
    description: [
      'Pay-as-you-go model: no subscriptions required',
      '1 credit = 1 photorealistic image',
      'Super easy: upload one selfie and get multiple images',
      'Bundles: choose a pack of photos and get similar ones from one selfie',
      'Selectors: choose camera, angle, lighting, background, and more',
      'Custom Instructions: specify every detail with prompts',
      'Feed: get inspired by scrolling through images',
      'Models: follow real creators and their stunning creations',
      'Best value for frequent users!',
    ],
    buttonText: 'Get 50 credits',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          With Lumipic, you only pay for what you use—no subscriptions, no hidden fees.
          It’s as simple as 1 credit = 1 photorealistic image. Just upload your selfie,
          explore the options, and start creating stunning images effortlessly!
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: 6, md: 4 }}
            key={tier.amount}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: '1px solid',
                borderColor: 'divider',
                boxShadow: `0 4px 12px rgba(0, 0, 0, 0.1)`,
              }}
            >
              <CardContent>
                <Typography
                  component="h3"
                  variant="h6"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {tier.amount} Credits
                  {tier.amount === 20 && (
                    <Chip icon={<AutoAwesomeIcon />} label="Best Value" />
                  )}
                </Typography>
                <Typography
                  component="h3"
                  variant="h2"
                  sx={{ mt: 1 }}
                >
                  ${tier.price.toFixed(2)}
                </Typography>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon sx={{ color: 'primary.main', width: 20 }} />
                    <Typography variant="subtitle2">{line}</Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  color={tier.buttonColor}
                  onClick={() => {
                    window.location.href = 'https://app.lumipic.pro';
                  }}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}