import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';

const userTestimonials = [
  {
    name: 'Emily R.',
    occupation: 'Marketing Specialist',
    testimonial:
      'I needed a professional photo for LinkedIn but couldn’t afford a photoshoot. With just one selfie, Lumipic gave me a series of portraits that look like I hired a top-tier photographer. The process was so simple that I had my images ready within minutes, no tech skills required!',
  },
  {
    name: 'Sarah T.',
    occupation: 'Stay-at-Home Mom',
    testimonial:
      'As a busy mom, vacations are a dream I can’t afford right now—both in time and money. I uploaded one selfie to Lumipic, and it generated stunning vacation pictures of me in beautiful destinations. I never thought it could be so easy to get pictures that make me look like I’ve been around the world!',
  },
  {
    name: 'Mike J.',
    occupation: 'Mechanic',
    testimonial:
      'I’ve always dreamed of riding a Harley, but life kept getting in the way. Lumipic made my dream come true in minutes! All I did was upload a photo, and I got incredible images of me cruising down the highway on a Harley. If I can figure it out, trust me—anyone can!',
  },
  {
    name: 'Linda P.',
    occupation: 'Teacher',
    testimonial:
      'I wanted to surprise my grandma with a family portrait. I sent a selfie to Lumipic, and it generated beautiful images of me in a classic family portrait style. She couldn’t believe how real it looked! It was so easy that I didn’t even need to call my tech-savvy nephew for help.',
  },
  {
    name: 'Jessica K.',
    occupation: 'College Student',
    testimonial:
      'I’ve always been shy about posting on Instagram, but Lumipic gave me the confidence to shine. With one selfie, I got tons of glamorous images that look like they were taken by a professional photographer. If I could figure it out, anyone can—it’s that simple!',
  },
  {
    name: 'David L.',
    occupation: 'Aspiring Actor',
    testimonial:
      'I needed headshots to start pursuing acting, but I couldn’t afford the price of a photoshoot. Lumipic made it ridiculously easy to get professional-quality headshots from just one photo. The whole process was intuitive—I didn’t even have to think twice about it!',
  },
];

export default function Testimonials() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          See how Lumipic has transformed everyday moments into stunning memories for
          our users. Discover how easy it is to bring your ideas to life with just one selfie.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                padding: 2,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.secondary', marginBottom: 2 }}
                >
                  {testimonial.testimonial}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {testimonial.occupation}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}