import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const legalLinks = [
  { name: 'Terms', href: 'https://app.lumipic.pro/terms-of-service.pdf' },
  { name: 'Privacy', href: 'https://app.lumipic.pro/privacy-policy.pdf' },
  { name: 'DMCA', href: 'https://app.lumipic.pro/dmca.pdf' },
];

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: { xs: 4, sm: 0 },
        py: { xs: 4, sm: 6 },
      }}
    >
      {/* Left Section: Lumipic Pro and Support Email */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Lumipic Pro
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Support: <Link href="mailto:support@lumipic.pro">support@lumipic.pro</Link>
        </Typography>
      </Box>

      {/* Right Section: Legal Links */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
        }}
      >
        {legalLinks.map((link) => (
          <Link
            key={link.name}
            color="text.secondary"
            variant="body2"
            href={link.href}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {link.name}
          </Link>
        ))}
      </Box>
    </Container>
  );
}