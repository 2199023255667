import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded ? [...expanded, panel] : expanded.filter((item) => item !== panel),
    );
  };

  const faqItems = [
    {
      name: 'How much does it cost to create one picture?',
      description: 'Creation of one picture usually costs around 1 credit.',
    },
    {
      name: 'How much do I need to wait for my picture to be generated?',
      description:
        'Usually it takes about a minute to process one image. If you received free credits, you will need to wait until jobs from paid users are processed. Purchasing credits increases the speed by avoiding this queue.',
    },
    {
      name: 'Picture doesn\'t match my prompt',
      description:
        'The picture is generated by AI, not us. Even though we\'re trying to improve prompting by adding custom additional instructions depending on your choice, it\'s not yet possible to fully control the output result. You can try to put guidance and other advanced settings on max values to get better results.',
    },
    {
      name: 'I have an issue with the payment',
      description:
        'If you have an issue with the payment, either open invoice page or profile page (right upper corner), get info about your transaction (payment ID, payment link, payment method etc.) and send them along with your User ID through support email or telegram support agent.',
    },
    {
      name: 'Is my data safe? Will anyone see the content I create?',
      description:
        'Yes, your data is safe. We don\'t keep source photos that you send to us. We do keep the content you create on our servers, but they are not connected with your profile directly. The only possible way to access your content is to do it from your account by following the link of the job. You may also use the delete button to remove either all or the exact image, which will delete it from our servers.',
    },
  ];

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqItems.map((faq, index) => (
          <Accordion
            key={faq.name}
            expanded={expanded.includes(`panel${index}`)}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {faq.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}